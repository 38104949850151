*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
  text-align: center;
  margin: 0 auto;
  padding: 1.875rem;
}
@media (min-width: 65.25em) {
  body {
    width: 50vw;
  }
}
@media (min-width: 40em) {
  body {
    width: 80vw;
  }
}

.u-mt-huge {
  margin-top: 3.5rem !important;
}

.u-mt-large {
  margin-top: 2.5rem !important;
}

.u-mt-medium {
  margin-top: 1.75rem !important;
}

.u-mt-small {
  margin-top: 1.25rem !important;
}

.u-mt-tiny {
  margin-top: 6px !important;
}

.u-text-subheading {
  font-size: 0.875rem;
  font-weight: 700 !important;
  color: var(--col-txt1) !important;
}

.u-divider {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: var(--col-bg-card);
}
@media (min-width: 40em) {
  .u-divider {
    display: none;
  }
}

.span-all {
  grid-column: 1/-1;
}

body {
  font-family: "Libre Franklin", sans-serif;
  font-size: 1.25rem;
}

h1,
h2,
h3 {
  margin-top: 0;
}

h1 {
  color: var(--col-txt2);
  font-size: 1.5rem;
}
@media (min-width: 65.25em) {
  h1 {
    font-size: 1.75rem;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.logo img {
  width: 4rem;
}
@media (min-width: 65.25em) {
  .logo img {
    width: 5.5rem;
  }
}

.heading {
  color: #969696;
  font-size: 1.5rem;
}
@media (min-width: 65.25em) {
  .heading {
    font-size: 2.5rem;
  }
}
.heading span {
  font-weight: 700;
  color: #151f29;
}

.subheading {
  font-size: 0.875rem;
}

.illustration {
  overflow: hidden;
}
.illustration img {
  width: 100%;
}
@media (min-width: 65.25em) {
  .illustration img {
    width: 60%;
  }
}

.footer {
  margin-top: 100px;
}
.footer__icons {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.footer__icon {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #969696;
  padding: 10px;
  cursor: pointer;
}
.footer__icon:first-child img {
  width: 70%;
}
.footer__icon img {
  width: 100%;
  fill: #4f7df3;
}
.footer__credit {
  font-size: 1.125rem;
  color: #969696;
}

.form {
  text-align: center;
  padding: 0.75rem;
  font-family: inherit;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 0;
}
@media (min-width: 65.25em) {
  .form {
    flex-direction: row;
    align-items: start;
    text-align: start;
  }
}
.form__input, .form__btn {
  display: inline-block;
  width: 100%;
  border-radius: 100px;
  padding: 1rem 1.5rem;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
}
@media (min-width: 65.25em) {
  .form__control {
    width: 50%;
  }
}
.form__input {
  border: 1px solid #969696;
  color: #969696;
  position: relative;
}
.form__input:focus {
  color: #151f29;
}
.form__input.error {
  border-color: #ff5263;
}
.form__error {
  color: #ff5263;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  display: none;
  padding: 0 1.25rem;
}
.form__btn {
  border: none;
  min-width: 150px;
  color: white;
  background-color: #4f7df3;
  margin-top: 0.75rem;
  font-weight: 600;
  box-shadow: 0 5px 5px rgba(79, 125, 243, 0.3);
  cursor: pointer;
  transition: transform 0.2s;
}
@media (min-width: 65.25em) {
  .form__btn {
    width: 20%;
    margin-left: 0.75rem;
    margin-top: 0;
  }
}
.form__btn:hover, .form__btn:focus, .form__btn:active {
  filter: brightness(1.1);
}
.form__btn:active {
  box-shadow: 0 3px 5px rgba(79, 125, 243, 0.5);
  transform: translateY(1px);
}