@use "utils" as *;

// Margin

.u-mt-huge {
  margin-top: rem(56) !important;
}
.u-mt-large {
  margin-top: rem(40) !important;
}

.u-mt-medium {
  margin-top: rem(28) !important;
}

.u-mt-small {
  margin-top: rem(20) !important;
}

.u-mt-tiny {
  margin-top: 6px !important;
}

// Sub-text
.u-text-subheading {
  font-size: rem(14);
  font-weight: 700 !important;
  color: var(--col-txt1) !important;
}

// Divider
.u-divider {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: var(--col-bg-card);

  @include bp("medium") {
    display: none;
  }
}

.span-all {
  grid-column: 1/-1;
}
