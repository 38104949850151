@use "utils" as *;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%; //default 16px
}

body {
  min-height: 100vh;
  overflow-x: hidden;
  text-align: center;
  margin: 0 auto;
  @include wrapper;

  @include bp(large) {
    width: 50vw;
  }

  @include bp(medium) {
    width: 80vw;
  }
}
